import React from "react"
import Layout from "../components/Layout";
import Sections from '../components/Sections';
import Seo from "../components/Seo2";
import Page from "../components/Page";

import { meta, page, sections } from '../data/hr-blogg.json';

const HrBlogg = () => {
  return <Layout>
      <Seo {...meta} />
      <Page {...page} />
      <Sections sections={sections} />
    </Layout>
}

export default HrBlogg
